module.exports = {
  siteTitle: "Sudarshan Gaikaiwari", // <title>
  shortSiteTitle: "Blog - Sudarshan Gaikaiwari", // <title> ending for posts and pages
  siteDescription: "Sudarshan Gaikaiwari's Blog.",
  siteUrl: "https://sudarshan.org",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "sudarshan gaikaiwari",
  authorTwitterAccount: "sudarshan",
  // info
  headerTitle: "sudarshan gaikaiwari",
  headerSubTitle: "mostly programming",
  // manifest.json
  manifestName: "Sudarshan Gaikaiwari's Blog",
  manifestShortName: "Blog",
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/sudarshang" },
    { name: "twitter", url: "https://twitter.com/sudarshan" }
  ]
};
